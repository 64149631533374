var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "2/3", pageTitle: "Company Details" } },
    [
      _c("vx-card", [
        _c(
          "div",
          {
            staticClass: "tabs-container px-6 pt-6",
            attrs: { slot: "no-body" },
            slot: "no-body",
          },
          [
            _c(
              "vs-tabs",
              { staticClass: "tabs-shadow-none" },
              [
                _c(
                  "vs-tab",
                  {
                    staticClass: "fixed-height",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-settings",
                      label: "Company Info",
                    },
                  },
                  [
                    _c("div", { staticClass: "mt-4 ml-0 mr-0" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap items-center mb-base" },
                        [
                          _c("vs-avatar", {
                            staticClass: "mr-4 mb-4 company-logo-avatar",
                            attrs: {
                              src: _vm.form.logoUrl,
                              icon: "cloud_upload",
                              size: "70px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.companyLogoInput.click()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "vx-row mb-6" }, [
                        _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                          _c("span", [_vm._v("Company Name*")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "vx-col sm:w-4/5 w-full" },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.companyName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "companyName", $$v)
                                },
                                expression: "form.companyName",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "vx-row mb-6" }, [
                        _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                          _c("span", [_vm._v("Company Type")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "vx-col sm:w-4/5 w-full" },
                          [
                            _c("v-select", {
                              attrs: {
                                value: _vm.selectedCompanyType,
                                placeholder: "CompanyType",
                                options: _vm.companyTypeOptions,
                                disabled: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "vx-row mb-6" }, [
                        _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                          _c("span", [_vm._v("Address 1")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "vx-col sm:w-4/5 w-full" },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.address1,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "address1", $$v)
                                },
                                expression: "form.address1",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "vx-row mb-6" }, [
                        _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                          _c("span", [_vm._v("Address 2")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "vx-col sm:w-4/5 w-full" },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.address2,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "address2", $$v)
                                },
                                expression: "form.address2",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "vx-row mb-6" }, [
                        _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                          _c("span", [_vm._v("City")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "vx-col sm:w-4/5 w-full" },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.city,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "city", $$v)
                                },
                                expression: "form.city",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "vx-row mb-6" }, [
                        _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                          _c("span", [_vm._v("County / State")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "vx-col sm:w-4/5 w-full" },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.state,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "state", $$v)
                                },
                                expression: "form.state",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "vx-row mb-6" }, [
                        _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                          _c("span", [_vm._v("Country")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "vx-col sm:w-4/5 w-full" },
                          [
                            _c("v-select", {
                              staticClass: "style-chooser",
                              attrs: {
                                value: _vm.selectedCountry,
                                "append-to-body": "",
                                disabled: "",
                                placeholder: "Country",
                                options: _vm.countryOptions,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "vx-row mb-6" }, [
                        _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                          _c("span", [_vm._v("Require 2FA")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "vx-col sm:w-4/5 w-full ml-auto" },
                          [
                            _c("vs-switch", {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.requires2FA,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "requires2FA", $$v)
                                },
                                expression: "form.requires2FA",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap justify-end" },
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: { color: "danger" },
                              on: { click: _vm.onCancel },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    staticClass: "fixed-height-company",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-user",
                      label: "Users",
                    },
                  },
                  [
                    _c("company-users", {
                      attrs: { companyId: _vm.companyId },
                    }),
                  ],
                  1
                ),
                _c(
                  "vs-tab",
                  {
                    staticClass: "fixed-height-company",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-globe",
                      label: "Applications",
                    },
                  },
                  [
                    _c("company-applications", {
                      attrs: { companyId: _vm.companyId },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }